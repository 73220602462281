import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import ShareOptions from '../components/ShareOptions';
import Layout from '../components/Layout';
import CareerForm from '../components/CareerForm';
import CTA from '../components/CTA';

import '../styles/posts.less';

interface Markdown {
    data: MarkdownData;
}

interface MarkdownData {
    markdownRemark: MarkdownRemark;
}

interface MarkdownRemark {
    html: string;
    frontmatter: {
        date: Date;
        slug: string;
        title: string;
        excerpt: string;
    };
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "DD.MM.YYYY")
                slug
                title
                excerpt
            }
        }
    }
`;

const CareerPost = ({ data }: Markdown): JSX.Element => {
    const { markdownRemark }: MarkdownData = data;
    const { frontmatter, html }: MarkdownRemark = markdownRemark;

    return (
        <>
            <Helmet title={`${frontmatter.title} - Careers`} />
            <Layout>
                <div className="page">
                    <div className="careers__post">
                        <div className="post__social">
                            <ShareOptions title={frontmatter.title} description={frontmatter.excerpt} />
                        </div>
                        <div className="post">
                            <div className="post__date">Posted: {frontmatter.date}</div>
                            <h1 className="post__title">{frontmatter.title}</h1>
                            <p className="post__excerpt">{frontmatter.excerpt}</p>
                            <div className="post__cnt" dangerouslySetInnerHTML={{ __html: html }} />
                        </div>
                    </div>
                    <div className="careers__form">
                        <CareerForm />
                    </div>
                    <div className="careers__back">
                        <CTA classname="details" path="/careers/" text="Back to Careers" />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default CareerPost;
