import * as React from 'react';
import axios, { AxiosResponse } from 'axios';

import { validateForm, isValidUrl, MESSAGE_FORM_INVALID, MESSAGE_MAX_FILE_SIZE, MESSAGE_URL_INVALID, MESSAGE_ALLOWED_FILE_TYPES } from '../utils/validation';
import { MIN_LENGTH, EMAIL_REGEX, IS_INVALID, IS_REQUIRED, MAX_FILE_SIZE, ALLOWED_FILE_TYPES } from '../constants';
import CTA from './CTA';

interface CareerFormParams {
    name: string;
    email: string;
    profileUrl: string;
    cv: File | string;
    cvFilename: string;
    coverLetter: string;
    errors: {
        name: boolean | null;
        email: boolean | null;
        profileUrl: boolean | null;
        cv: boolean | null;
        coverLetter: boolean | null;
    };
}
interface FormError {
    invalid: boolean;
    message: string;
}
type FieldNames = 'name' | 'email' | 'profileUrl' | 'coverLetter';

const CareerForm = (): JSX.Element => {
    const [disabled, setDisabled]: [boolean, Function] = React.useState<boolean>(true);
    const [formSent, setFormSent]: [boolean, Function] = React.useState<boolean>(false);
    const [formError, setFormError]: [FormError, Function] = React.useState<FormError>({ invalid: false, message: MESSAGE_FORM_INVALID });
    const [state, setState]: [CareerFormParams, Function] = React.useState<CareerFormParams>({
        name: '',
        email: '',
        profileUrl: '',
        cv: '',
        cvFilename: '',
        coverLetter: '',
        errors: {
            name: null,
            email: null,
            profileUrl: null,
            cv: null,
            coverLetter: null,
        },
    });
    const allowedFileTypes = ALLOWED_FILE_TYPES.toString();

    const handleChange = (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        const value = e.target.value;
        const name = e.target.name as FieldNames;
        const errors = state.errors;
        let errorMessage = MESSAGE_FORM_INVALID;

        switch (name) {
            case 'email':
                errors[name] = !EMAIL_REGEX.test(value);
                break;
            case 'profileUrl':
                const isInvalid = !isValidUrl(value);
                if (isInvalid) {
                    errorMessage = MESSAGE_URL_INVALID;
                }
                errors[name] = isInvalid;

                break;
            default:
                errors[name] = value.length < MIN_LENGTH ? true : false;
                break;
        }

        setDisabled(!validateForm(errors));
        setFormError({ invalid: !validateForm(errors), message: errorMessage });
        setState((prevState: CareerFormParams) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleFileInput = (e: { target: HTMLInputElement }) => {
        const files: FileList | null = e.target.files;
        const errors = state.errors;
        let errorMessage = MESSAGE_FORM_INVALID;
        errors.cv = false;

        if (files?.length) {
            const file = files[0];
            const filename = file.name;

            if (file.size >= MAX_FILE_SIZE) {
                errorMessage = MESSAGE_MAX_FILE_SIZE;
                errors.cv = true;
            }

            if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                errorMessage = MESSAGE_ALLOWED_FILE_TYPES;
                errors.cv = true;
            }

            setDisabled(!validateForm(errors));
            setFormError({ invalid: !validateForm(errors), message: errorMessage });
            setState((prevState: CareerFormParams) => {
                return {
                    ...prevState,
                    cv: file,
                    cvFilename: filename,
                };
            });
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', state.name.trim());
        formData.append('email', state.email.trim());
        formData.append('profileUrl', state.profileUrl.trim());
        formData.append('cv', state.cv);
        formData.append('message', state.coverLetter.trim());

        const apiBaseUrl = process.env.GATSBY_API_URL as string;
        axios({
            method: 'post',
            url: 'career.php',
            baseURL: apiBaseUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: formData,
        })
            .then(function (response: AxiosResponse) {
                setFormSent(true);
            })
            .catch(function () {
                setFormError({ invalid: true, message: MESSAGE_FORM_INVALID });
            });
    };

    const message: JSX.Element = (
        <div className="form-wrapper">
            <h3 className="form__title">Thanks!</h3>
            <p className="form__description">Your application has been received. In case of further interest, we will contact you.</p>
        </div>
    );

    const form: JSX.Element = (
        <div className="form-wrapper">
            <h3 className="form__title">Apply</h3>
            <p className="form__description">If you’re interested in working with leaders in video streaming technology then we’d love to hear from you. Please complete the details in the form and click Submit.</p>
            <form className="form" onSubmit={handleSubmit}>
                <fieldset>
                    <div className="row">
                        <label htmlFor="name" className={`label ${IS_REQUIRED} ${state.errors.name ? IS_INVALID : ''}`} aria-label="Name">
                            <input className="input" type="text" placeholder="Name" name="name" id="name" value={state.name} onChange={handleChange} />
                        </label>
                        <label htmlFor="email" className={`label ${IS_REQUIRED} ${state.errors.email ? IS_INVALID : ''}`} aria-label="Email">
                            <input className="input" type="email" placeholder="E-mail" name="email" id="email" value={state.email} onChange={handleChange} />
                        </label>
                    </div>
                    <div className="row">
                        <label htmlFor="profileUrl" className={`label ${IS_REQUIRED} ${state.errors.profileUrl ? IS_INVALID : ''}`} aria-label="LinkedIn profile URL">
                            <input className="input" type="url" placeholder="LinkedIn profile URL" name="profileUrl" id="profileUrl" value={state.profileUrl} onChange={handleChange} />
                        </label>
                    </div>
                    <div className="row">
                        <label htmlFor="cv" className={`label label--file-input ${IS_REQUIRED} ${state.errors.cv ? IS_INVALID : ''}`} aria-label="CV">
                            <input className="input input--file" type="text" readOnly={true} placeholder="CV" name="filename" id="filename" value={state.cvFilename} />
                            <input className="input" type="file" placeholder="CV" name="cv" id="cv" onChange={handleFileInput} accept={allowedFileTypes} multiple={false} />
                        </label>
                    </div>
                    <div className="row">
                        <label htmlFor="coverLetter" className={`label ${IS_REQUIRED} ${state.errors.coverLetter ? IS_INVALID : ''}`} aria-label="Cover letter">
                            <textarea className="textarea" placeholder="Cover letter" name="coverLetter" id="coverLetter" value={state.coverLetter} onChange={handleChange} />
                        </label>
                    </div>
                    <div className="row">
                        <CTA classname="submit" text="Submit" type="submit" disabled={disabled} />
                        {formError.invalid && <div className="error">{formError.message}</div>}
                    </div>
                </fieldset>
            </form>
        </div>
    );

    return formSent ? message : form;
};

export default CareerForm;
